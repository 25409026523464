.home-page {
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-items: center;
}

.home-background {
    position: fixed;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.home-background .photo {
    background-repeat: no-repeat;
    background-size: cover;
    background-color: rgba(0,0,0,0.5);
    width: 100%;
    height: 256px;
}

.home-overflowing {
    width: 150%;
    height: 150%;
}

.home-panel {
    z-index: 1;
    margin: auto;
    background-color: rgba(0,0,0,0.8);
    border-radius: 5px;
    padding: 20px
}

.home-panel .icon {
    text-align: center;
    margin: auto;
}

.home-panel .description {
    color: lightgray;
}

.home-panel .buttons {
    text-align: center;
    margin: auto;
}

.photo {
    filter: blur(4px) brightness(25%);
}

.photo:hover {
    filter: blur(0px) brightness(60%);
}