body {
  margin: 0;
  height: 100vh;
  background: black;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100vh;
}

h1,h2,h3 {
  color: white;
}

.layout {
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.logo-main {
  color: white
}
.logo-highlight {
  color: #daa520;
  padding: 2px;
}

/* ANT Overrides */
.ant-input-search-button {
  background-color: darkgoldenrod;
}

.ant-btn-primary {
  box-shadow: none;
}

.ant-card {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.ant-card-cover {
  height: 100%
}

.ant-card-actions {
  user-select: none;
}

.ant-layout-content {
  height: calc(100vh - 64px);
}

.ant-layout-header {
  padding-inline: 15px !important;
}

